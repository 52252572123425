<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Mark Employee Attendance</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-layout wrap justify-start v-if="employeeList && temp">
          <v-flex xs12 align-self-center text-left fill-height pa-4>
            <span style="font-size:18px;color:#000" class="poppinssemibold"
              >Today -
              <span style="font-size:18px;color:green">{{
                formatDate(date)
              }}</span>
            </span>
          </v-flex>
          <v-flex
            xs12
            align-self-center
            fill-height
            pa-4
            v-if="temp && !hasTaken"
          >
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr class="poppinssemibold">
                    <th style="color:red !important" class="text-left">
                      Employee Name
                    </th>
                    <th style="color:red !important" class="text-left">
                      Mark Attendance
                    </th>
                    <!-- <th style="color:red !important" class="text-left">
                      Leave Status
                    </th> -->
                  </tr>
                </thead>
                <tbody v-if="temp">
                  <tr
                    v-for="(item, i) in employeeList"
                    :key="i"
                    class="poppinsregular"
                  >
                    <td class="text-left">
                      <span v-if="item.userName">
                        {{ item.userName }}
                      </span>
                      <span v-else>-</span>
                    </td>
                    <td class="text-left" v-if="flag">
                      <v-layout wrap justify-start v-if="flag">
                        <v-flex xs12 sm4 md3 lg3 pa-2 align-self-center>
                          <v-btn
                            dark
                            tile
                            block
                            small
                            title="Click to change status"
                            @click="changeStatus(item.isPresent, i)"
                            :color="item.isPresent ? 'primary' : 'red'"
                          >
                            <span v-if="item.isPresent">
                              Present
                            </span>

                            <span v-else>{{ item.leaveType }} </span>
                          </v-btn>
                        </v-flex>
                        <v-spacer></v-spacer>
                        <!-- <v-flex xs12 sm6 md8 lg8 align-self-center>
                          <v-btn
                            tile
                            small
                            outlined
                            dark
                            color="#000"
                            class="text-none rounded-xl"
                            @click="changeStatus(item.isPresent, i)"
                            ><v-icon small>mdi-pencil</v-icon> &nbsp;
                            <span>Change</span>
                          </v-btn>
                        </v-flex> -->
                        <!-- <v-flex xs12 pa-6 text-center>
                          <v-card
                            class="pa-2"
                            elevation="9"
                            :color="item.isPresent ? 'red' : 'green'"
                          >
                            g
                          </v-card>
                        </v-flex> -->
                      </v-layout>
                    </td>
                    <!-- <td class="text-left">
                      <span v-if="item.leaveType && !item.isPresent">
                        {{ item.leaveType }}
                      </span>
                      <span v-else>-</span>
                    </td> -->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- <v-card tile elevation="3" outlined>
              <v-layout wrap justify-center py-4 pa-2>
                <v-flex xs12 px-4 py-2 text-center>
                  <span style="font-family: poppinsbold;font-size:17px">{{ item.name }}</span>
                </v-flex>
                <v-flex xs12 px-4 py-0 text-center>
                 <i>
                    <span class="poppinssemibold" style="font-size:13px"> ( {{ item.type }} )</span>
                 </i>
                </v-flex>

                <v-flex xs12 py-4>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 sm6 align-self-center text-right px-2 py-2>
                  <v-btn
                    color="teal"
                    style="font-family: poppinsmedium"
                    small
                    block
                    class="rounded-xl"
                    dark
                    :to="'/Admin/editUnit?id=' + item._id"
                  >
                    <span style="color:#ffffff">Edit</span>
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm6 align-self-center text-left px-2 py-2>
                  <v-dialog v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="font-family: poppinsmedium"
                        dark
                        block
                        class="rounded-xl"
                        v-on="on"
                        v-bind="attrs"
                        color="error"
                      >
                        Delete
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        Unit?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-card> -->
          </v-flex>
          <v-flex xs12 align-self-center text-center pa-12 v-if="!hasTaken">
            <v-dialog persistent v-model="verifyDialog" max-width="750px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-large
                  style="font-family: poppinsmedium"
                  tile
                  v-on="on"
                  class="rounded-xl"
                  v-bind="attrs"
                  color="error"
                >
                  Submit Attendance
                </v-btn>
              </template>
              <v-card tile class="pa-6">
                <v-card-title class="poppinssemibold"
                  >Confirm whether you want to submit today's
                  attendance?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    dark
                    small
                    @click="verifyDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="error darken-1"
                    dark
                    small
                    @click="(verifyDialog = false), submitData()"
                    >Submit</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
          <v-flex xs12 pa-12 align-self-center text-center v-if="hasTaken">
            <span class="poppinssemibold"
              >Today's attendance has already taken!
            </span>
          </v-flex>
          <v-dialog persistent v-model="absentDialog" max-width="600px">
            <v-card>
              <!-- <v-card-title>
                <span class="headline">Leave Status</span>
              </v-card-title> -->
              <v-card-text>
                <v-layout wrap>
                  <v-flex xs12 align-self-center text-center pa-6>
                    <span
                      style="font-size:18px;border-bottom:2px solid #000"
                      class="poppinssemibold"
                      >Leave Status</span
                    >
                  </v-flex>
                  <v-flex xs12 md12 lg12 px-8 pt-8>
                    <v-select
                      v-model="leaveType"
                      :items="leaveTypes"
                      outlined
                      dense
                      label="Choose Leave Type"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error darken-1" text @click="DialogClose()">
                  Close
                </v-btn>
                <v-btn
                  v-if="leaveType"
                  color="blue darken-1"
                  text
                  @click="
                    saveChange(), (absentDialog = false), (leaveType = 'Leave')
                  "
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 pa-16>
            <span>
              No Employee found!
            </span>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      pageCount: 1,
      appLoading: false,
      currentPage: 1,
      flag: true,
      date: new Date(),
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      leaveTypes: ["Leave", "Absent"],
      leaveType: "Leave",
      category: [],
      data: [],
      employeeList: null,
      absentDialog: false,
      position: -1,
      temp: true,
      newOut: [],
      verifyDialog: false,
      hasTaken: false,
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    DialogClose() {
      this.employeeList[this.position].isPresent = true;

      this.flag = false;
      console.log("re-check=", this.employeeList);
      console.log(
        this.employeeList[this.position].isPresent + " -" + this.position
      );
      this.absentDialog = false;
      this.flag = true;
    },
    changeStatus(status, pos) {
      this.temp = false;
      this.position = pos;
      if (status) {
        this.absentDialog = true;
      } else {
        this.employeeList[pos].isPresent = true;
        // this.msg = "Rechanged";
        // this.showsnackbar = true;

        var subObj = {};
        this.newOut = [];
        for (let i = 0; i < this.employeeList.length; i++) {
          if (i == pos) {
            this.employeeList[i].leaveType = null;
            this.msg = "Leave status updated again!";
            this.showsnackbar = true;
          }
          subObj.empId = this.employeeList[i]._id;
          subObj.isPresent = this.employeeList[i].isPresent;
          if (this.employeeList[i].leaveType)
            subObj.leaveType = this.employeeList[i].leaveType;
          this.newOut.push(subObj);
          subObj = {};
        }

        console.log("Re out", this.newOut);
        console.log("Re Emp list", this.employeeList);
      }
      this.temp = true;
    },

    curEmp(value, pos) {
      if (!value) this.absentDialog = true;
      // if (!value) {
      //   this.employeeList[pos].isPresent = false;
      //   this.absentDialog = true;
      // }
      else {
        var subObj = {};
        this.newOut = [];
        for (let i = 0; i < this.employeeList.length; i++) {
          if (i == pos) {
            this.employeeList[i].leaveType = null;
            this.msg = "Leave status updated";
            this.showsnackbar = true;
          }
          subObj.empId = this.employeeList[i]._id;
          subObj.isPresent = true;
          // subObj.isPresent = this.employeeList[i].isPresent;
          if (this.employeeList[i].leaveType)
            subObj.leaveType = this.employeeList[i].leaveType;
          this.newOut.push(subObj);
          subObj = {};
        }
        console.log("agin out", this.newOut);
        console.log("agin Emp list out", this.employeeList);
      }
      this.position = pos;
    },
    saveChange() {
      var subObj = {};
      this.newOut = [];
      for (let i = 0; i < this.employeeList.length; i++) {
        if (i == this.position) {
          this.employeeList[i].isPresent = false;
          this.employeeList[i].leaveType = this.leaveType;
          this.msg = "Leave status updated!";
          this.showsnackbar = true;
        }
        subObj.empId = this.employeeList[i]._id;
        subObj.isPresent = this.employeeList[i].isPresent;
        if (this.employeeList[i].leaveType)
          subObj.leaveType = this.employeeList[i].leaveType;
        this.newOut.push(subObj);
        subObj = {};
      }
      console.log("Init EMP=", this.employeeList);
      console.log("Init New=", this.newOut);
      this.temp = false;
      this.temp = true;
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/get/all/employeelist",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          page: 1,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.employeeList = response.data.data;
          this.hasTaken = response.data.hasTakenAttandance;
          if (this.hasTaken) {
            this.$router.push("/Admin/Employee");
          }
          this.employeeList.map((x) => {
            x.isPresent = true;
          });
          var subObj = {};
          this.newOut = [];
          for (let i = 0; i < this.employeeList.length; i++) {
            subObj.empId = this.employeeList[i]._id;
            subObj.isPresent = this.employeeList[i].isPresent;
            this.newOut.push(subObj);
            subObj = {};
          }

          // this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    submitData() {
      var newDate = new Date();
      newDate = JSON.stringify(newDate);
      newDate = newDate.slice(1, 11);
      var data = {};
      data["date"] = newDate;
      data["attandance"] = this.newOut;

      this.appLoading = true;
      axios({
        url: "/mark/attandance",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var month = dt.getMonth();
      dt = dt.toString();
      var strTime = year + "/" + month + "/" + day;
      return strTime;
    },
  },
};
</script>
